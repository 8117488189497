.app {
  background-color: var(--background-color);
  color: var(--text-color);
  -webkit-transition: background-color 0.3s ease-out;
  -moz-transition: background-color 0.3s ease-out;
  -o-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

.fade-in {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.app.dark {
  --background-color: #1f1f1f;
  --text-color: #fff;
}

.app.light {
  --background-color: #f5f5f5;
  --text-color: #000;
}
