$color-primary-1: #ff5403;
$color-primary-2: #90c0e8;
$color-primary-3: #6fc2c5;
$color-primary-4: #386163;
$color-primary-5: #486074;
$color-primary-6: #cd921e;
$color-primary-7: #84a1ff;
$color-primary-8: #ffcc00;

$color-secondary-1: #f1f1f1;
$color-secondary-2: #2f2f2f;
$color-secondary-3: #828282;
$color-secondary-4: #e1e1e1;
$color-secondary-5: #ffffff;
$color-secondary-6: #000000;

$border-radius-1: 10px;
$border-radius-2: 20px;
$border-radius-3: 25px;
$border-radius-4: 30px;
$border-radius-5: 45px;

$text-color-light: #ffffff;
$text-color-dark: #000000;

$dark-theme-background: #1f1f1f;
$light-theme-background: lighten($color-secondary-1, 10%);

$p-color-dark: #8a94a6;

$font-family-primary: 'Poppins', sans-serif;

// Small devices
$screen-xss: 480px;
// Mobile breakpoint
$screen-xs: 576px;
// Tablet breakpoint
$screen-sm: 768px;
// Laptop breakpoint
$screen-md: 1280px;
// Desktop breakpoint
$screen-lg: 2880px;
// Large desktop breakpoint
$screen-xl: 3072px;
