@import '../../util/variables';

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 7%;
  position: relative; // Keeps its place before becoming sticky

  /* Sticky Navigation */
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Optional shadow for better visibility
    z-index: 1000; // Make sure it stays on top of the content
    transition: background-color 0.3s ease-in-out; // Smooth background color transition

    // Dark theme sticky style
    &.dark {
      background-color: $dark-theme-background;
    }

    // Light theme sticky style
    &.light {
      background-color: $light-theme-background;
    }
  }

  .badge-soon {
    color: $color-secondary-3;
  }

  .theme-toggle {
    margin-left: 50px;
  }

  .name_logo {
    font-family: 'Cinzel', serif;
    font-size: 2rem;

    &:hover {
      color: $color-primary-1;
    }

    &__light {
      color: $color-primary-1;
    }

    &__dark {
      color: $color-secondary-5;
    }

    &__desktop {
      @media screen and (max-width: $screen-xss) {
        display: none;
      }

      @media screen and (min-width: $screen-xss) {
        display: block;
      }
    }

    &__monogram {
      @media screen and (max-width: $screen-xss) {
        display: block;
      }

      @media screen and (min-width: $screen-xss) {
        display: none;
      }
    }
  }

  .nav__brand {
    width: 50px;
    font-size: 20px;

    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      animation: spin 1s linear infinite;
    }
    @-moz-keyframes spin {
      100% {
        // -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        // -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        // -webkit-transform: rotate(360deg);
        // transform: rotate(360deg);
      }
    }
  }

  li {
    text-decoration: none;
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    font-weight: bold;

    .dark {
      color: $color-primary-1;
    }

    a:hover,
    a:active {
      color: $color-primary-1;
    }
  }

  .light {
    color: $color-secondary-3;
  }

  .nav__brand {
    cursor: pointer;
    a:hover {
      color: $text-color-light;
    }
  }

  a {
    text-decoration: none;
    color: $text-color-light;
  }

  .nav__links {
    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .nav__btn {
    display: none;
  }

  @media screen and (max-width: $screen-sm) {
    .nav__btn {
      display: block;
    }
  }
}

/* Sticky Class for Small Screens */
@media screen and (max-width: $screen-sm) {
  .nav__btn {
    &__checkbox {
      display: none;
    }

    &__button {
      background-color: $color-primary-1;
      height: 3rem;
      width: 3rem;
      position: fixed;
      top: 5rem;
      right: 2rem;
      border-radius: 50%;
      z-index: 2000;
      box-shadow: rgba($color-primary-1, 0.3) 0px 7px 29px 0px;
      text-align: center;
      cursor: pointer;
    }

    &__background {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      position: fixed;
      top: 7rem;
      right: 2.5rem;
      background-image: radial-gradient($text-color-dark, $color-primary-1);
      z-index: 1000;
      transition: transform 0.8s cubic-bezier(0.86, 0, 0.07, 1);
    }

    &__nav {
      height: 100vh;
      position: fixed;
      top: 0;
      right: -30%;
      z-index: 1500;

      opacity: 0;
      width: 0;
      transition: all 0.8s cubic-bezier(0.6, -0.55, 0.265, 1.55);
    }

    &__list {
      position: absolute;
      top: 50%;
      left: 15%;
      transform: translate(-50%, -50%);
      list-style: none;
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    &__item {
      margin: 1rem;
    }

    &__link {
      font-size: 2rem;
      font-weight: bold;
      color: $text-color-light;
      text-decoration: none;

      &:hover,
      &:active {
        color: $color-primary-1;
      }
    }

    &__checkbox:checked ~ &__background {
      transform: scale(80);
    }

    &__checkbox:checked ~ &__nav {
      opacity: 1;
      width: 100%;
    }

    &__icon {
      position: relative;
      margin-top: 1.5rem;
      margin-left: 0.7rem;
      &,
      &::before,
      &::after {
        width: 1.5rem;
        height: 2px;
        background-color: $text-color-light;
        display: inline-block;
      }

      &,
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        transition: all 0.2s;
      }

      &::before {
        top: -0.4rem;
      }
      &::after {
        top: 0.4rem;
      }
    }

    &__button:hover &__icon::before {
      top: -0.6rem;
    }

    &__button:hover &__icon::after {
      top: 0.6rem;
    }

    &__checkbox:checked + &__button &__icon {
      background-color: transparent;
    }

    &__checkbox:checked + &__button &__icon::before {
      top: 0;
      transform: rotate(135deg);
    }

    &__checkbox:checked + &__button &__icon::after {
      top: 0;
      transform: rotate(-135deg);
    }
  }
}

.nav__links {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Vertically center the links */
  justify-content: center; /* Optionally center horizontally if desired */
  height: 100%; /* Ensure it takes full height of the parent container */

  ul {
    display: flex; /* Make the ul a flexbox container */
    align-items: center; /* Vertically center the links within the ul */
    justify-content: center; /* Center the links horizontally within the ul */
    list-style: none; /* Remove bullets */
    padding: 0;
    margin: 0;

    li {
      padding: 0 10px; /* Adjust spacing between links */
    }

    .theme-toggle {
      margin-left: 20px; /* Adjust margin for the theme toggle */
    }
  }
}