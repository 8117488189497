.footer {
  padding: 2rem;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 2rem;
  }

  &__left,
  &__right {
    flex: 1;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    display: flex;
    justify-content: end;
  }

  &__branding {
    display: flex;
    flex-direction: column;
    align-items: center;

    .footer__tagline {
      margin: 1rem 0;
      font-size: 1rem;
      color: #ccc;
      max-width: 250px;
      text-align: center;
    }
  }

  &__right {
    .footer__links {
      display: flex;
      gap: 2rem;

      .footer__link-column {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .footer__link {
          text-decoration: none;
          color: #ccc;
          font-size: 1rem;

          &:hover {
            color: #ff5403;
          }
        }
      }
    }
  }

  &__copyright {
    margin-top: 2rem;
    font-size: 0.875rem;
    color: #777;
    text-align: center;
    width: 100%;
  }

  @media (min-width: 768px) {
    .footer__desktop {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .footer__mobile {
      display: none;
    }
  }

  /* Mobile Styles */
  @media (max-width: 768px) {
    .footer__desktop {
      display: none;
    }

    .footer__mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__container {
      flex-direction: column; // Stack everything vertically on mobile
      padding: 0 1rem;
      align-items: center;
      text-align: center;
    }

    &__left {
      max-width: 100%;
      align-items: center;
      text-align: center;

      .footer__branding {
        align-items: center; // Center-align items in branding
      }

      .footer__logo {
        margin-bottom: 1rem; // Add space between logo and tagline
      }

      .footer__tagline {
        margin-top: 1rem;
      }

      .footer__social {
        // justify-content: center;
        // margin-top: 0.5rem;
      }
    }

    &__right {
      max-width: 100%;
      align-items: center;
      text-align: center;
      margin-top: 1rem; // Space between left and right sections
      .footer__links {
        flex-direction: column; // Stack links in one column on mobile
        gap: 1rem;

        .footer__link-column {
          align-items: center; // Center-align links on mobile
        }
      }
    }
  }

  /* Extra small devices (smaller than 576px) */
  @media (max-width: 576px) {
    padding: 1rem;

    .footer__desktop {
      display: none;
    }

    .footer__mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .footer__tagline,
    .footer__link {
      font-size: 0.9rem;
    }
  }
}

.footer__social {
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
  margin-top: 0.5rem;

  a {
    color: #fff;
    transition: color 0.2s ease;

    &:hover {
      color: #ff5403;
    }
  }
}